<template>
  <div>
    <b-row>
      <b-col
        cols="6"
        md="2"
      >
        <div id="firma-grid-container">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="d-flex align-items-center"
            size="md"
            squared
            variant="success"
            :to="{ path: '/firma/firma-kart', params: { firmId: -1 } }"
            @click="OnAddClick()"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span>Firma Ekle</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <br>
    <!-- table -->
    <vue-good-table
      :columns="firms"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Firma Ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Firma listesi kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-10"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  class="mr-1"
                  squared
                  variant="outline-warning"
                  @click="onUpdateFirmBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteFirmBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import * as apiFirma from '@/api/islemler/firminventory'
import {
  BPagination, BFormSelect, BButtonGroup, BButton, VBModal, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButtonGroup,
    BButton,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newFirmId: 0,
      newFirmTitle: '',
      upFirmTitle: '',
      pageLength: 50,
      firms: [
        {
          label: 'Firma Ünvanı',
          field: 'firmTitle',
        },
        {
          label: 'Firma Telefon-1',
          field: 'firmTelephone1',
        },
        {
          label: 'Firma E-posta',
          field: 'firmEmail',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  created() {
    apiFirma.GetFirmsAll().then(res => {
      this.rows = res.data.list
    })
  },
  mounted() {
    this.getFirmsList()
  },
  methods: {
    ...mapActions({
      resetData: 'firmInventory/action_reset_firmInventory',
    }),
    getFirmsList() {
      apiFirma.GetFirmsAll().then(res => {
        this.rows = res.data.list
      })
    },
    onDataGridItemClicked(params) {
      return params
    },
    onUpdateFirmBtnClicked(row) {
      this.$router.push({ name: 'firma-kart', params: { firmId: row.firmInfoId } })
    },
    onDeleteFirmBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.firmTitle}' firma tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiFirma.DeleteFirm(row.firmInfoId).then(() => {
              this.getFirmsList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.firmTitle} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    OnAddClick() {
      this.resetData()
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
